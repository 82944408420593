import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { VENDOR_LOGO } from "../../../utils/data";
import { Col, Row, Button } from "react-bootstrap";
import { brandLogoWide } from "../../../utils";

const Header = (props) => {
  const context = useContext(PsContext);

  const handleLogout = () => {
    if (!window.confirm("Do you want to logout?")) return;

    context.logout();
  };

  const firstPath = () => {
    try {
      let s = props.path.split("/");
      return s[1];
    } catch (er) {}
  };

  const getStep = () => {
    if (firstPath() == "edit") return null;
    if (context.user.registration_completed == "0")
      return <li className="breadcrumb-item">Step-I</li>;
    else if (context.user.marks_uploaded == "0")
      return <li className="breadcrumb-item">Step-II</li>;
    else if (context.user.certificates_uploaded == "0")
      return <li className="breadcrumb-item">Step-III</li>;
    else if (context.user.application_declaration == "0")
      return <li className="breadcrumb-item">Application Preview</li>;
    else if (
      context.user.regular_course_applied == "0" &&
      context.user.self_course_applied == "0"
    )
      return <li className="breadcrumb-item">Step-IV</li>;
  };

  const getUrl = () => {
    if (props.path == "/apply")
      return <li className="breadcrumb-item">Course Apply</li>;
    if (props.path == "/my-profile")
      return <li className="breadcrumb-item">My Profile</li>;
    if (props.path == "/edit/marks")
      return <li className="breadcrumb-item">Edit Step-II</li>;
    if (props.path == "/edit/personal")
      return <li className="breadcrumb-item">Edit Step-I</li>;
    if (props.path == "/edit/certificates")
      return <li className="breadcrumb-item">Edit Step-III</li>;
    if (firstPath() == "payment-status")
      return <li className="breadcrumb-item">Payment Status</li>;
  };

  return (
    <>
      <header className="header fixed-top">
        {/*<div className=" header-top">
          <div className="container py-1">
            <Row>
              <Col md={6}></Col>
              <Col md={6} className="text-end">
                <a className="text-white">
                  Hi, {context.user.name} {context.user.initial}
                </a>
              </Col>
            </Row>
          </div>
        </div>*/}
        <div className="bg-white shadow ">
          <div className="container py-2 ">
            <Row>
              <Col md={4}>
                <Link to="" className="logo">
                  <img
                    src={brandLogoWide()}
                    alt=""
                    style={{ maxWidth: "100%", maxHeight: "55px" }}
                  />
                </Link>
              </Col>
              <Col md={4}></Col>
              <Col md={4} className="">
                <div className="text-end pt-2">
                  {(context.user.regular_course_applied == "1" ||
                    context.user.self_course_applied == "1") && (
                    <Link
                      to="/"
                      className="btn btn-info btn-sm ms-3 rounded-pill px-3"
                    >
                      <i className="fa-solid fa-house me-2"> </i>Dashboard
                    </Link>
                  )}
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    className=" ms-3 rounded-pill px-3"
                    onClick={(e) => handleLogout()}
                  >
                    <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>
                    Sign Out
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container py-2 bg-theme text-white rounded  d-none d-sm-block ">
          <Row>
            <Col md={6}>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                {getStep()}
                {getUrl()}
              </ol>
            </Col>
            <Col md={6} className="text-center text-md-end fw-bold">
              Welcome, {context.user.name} {context.user.initial}
            </Col>
          </Row>
        </div>
      </header>
    </>
  );
};

export default Header;
